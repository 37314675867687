import React from "react";
import Hero from "./Hero";
import Introduction from "./Introduction";
import Selection from "./Selection";
import Popular from "./Popular";
import HotBid from "../../components/HotBid";
import Collections from "./Collections";
import Discover from "./Discover";
import Description from "./Description";
import Description2 from "./Description/index-2";

const Home = () => {
  return (
    <>
      {/* <Hero /> */}
      <Description2 />
      <Introduction />
      {/* <Selection /> */}
      <Popular />
      <HotBid classSection="section" />
      {/* <Collections /> */}
      <Discover />
      {/* <Description /> */}
    </>
  );
};

export default Home;
