import React, { useState } from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import styles from "./Introduction.module.sass";
import Icon from "../../../components/Icon";
import Player from "../../../components/Player";
import Modal from "../../../components/Modal";
import Connect from "../../../components/Connect";
// import Bid from "../../../components/Bid";

const items = [
  {
    useCase: "Coaches & Mentors",
    title: "JK Molina",
    followers: "182.9K",
    currency: "4 Squads",
    price: "Max Capacity",
    logo1: "/images/content/circle-logo.jpg",
    company1: "Circle.so",
    logo2: "/images/content/mn-logo.jpg",
    company2: "Mighty Networks",
    image: "/images/content/slider-1.jpg",
    image2x: "/images/content/slider-1.jpg",
  },
  {
    useCase: "Networking",
    title: "Marco carrillo®",
    creator: "Enrico Cole",
    currency: "2.00 ETH",
    price: "$2,477.92",
    logo1: "/images/content/twitter-logo.png",
    company1: "Twitter",
    logo2: "/images/content/shapr-logo.png",
    company2: "Shapr",
    image: "/images/content/slider-2.jpg",
    image2x: "/images/content/slider-2.jpg",
  },
  {
    useCase: "Entertainment",
    title: "the creator network®",
    creator: "Enrico Cole",
    currency: "1.00 ETH",
    price: "$3,618.36",
    logo1: "/images/content/discord-logo.png",
    company1: "Discord",
    logo2: "/images/content/facebook-logo.png",
    company2: "Facebook",
    image: "/images/content/slider-3.jpg",
    image2x: "/images/content/slider-3.jpg",
  },
  {
    useCase: "Niche Communities",
    title: "the creator network®",
    creator: "Enrico Cole",
    currency: "1.00 ETH",
    price: "$3,618.36",
    logo1: "/images/content/discord-logo.png",
    company1: "Discord",
    logo2: "/images/content/reddit-logo.png",
    company2: "Reddit",
    image: "/images/content/slider-4.jpg",
    image2x: "/images/content/slider-4.jpg",
  },
];

const SlickArrow = ({ currentSlide, slideCount, children, ...props }) => (
  <button {...props}>{children}</button>
);

const Introduction = () => {
  const settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    // adaptiveHeight: true,
    nextArrow: (
      <SlickArrow>
        <Icon name="arrow-next" size="14" />
      </SlickArrow>
    ),
    prevArrow: (
      <SlickArrow>
        <Icon name="arrow-prev" size="14" />
      </SlickArrow>
    ),
  };

  const [visibleModalBid, setVisibleModalBid] = useState(false);

  return (
    <>
      <div className={cn("section-pb", styles.section)}>
        <div className={cn("container", styles.container)}>
          {/* <div className={styles.pill}>
            <div className={styles.stage}>
              For creators
            </div>
            <h2 className={cn("h3", styles.title)}>
              Connect and engage purposefully
            </h2>
          </div>
          <div className={styles.pill}>
            <div className={styles.stage}>
              For fans
            </div>
            <h2 className={cn("h3", styles.title)}>
              Be seen and heard by your favorite mentors
            </h2>
          </div> */}
          <div className={styles.stage}>Squadron 101</div>
          <h1 className={cn("h2", styles.title)}>Nurturing relationships, not numbers</h1>
          <p className={styles.info}>
            With over 35 million semi-pro creators reporting burnout, isolation, and negative outcomes from social media, <b>Squadron</b> helps them to better understand their audience, while giving users a more intimate experience with their favorite creators.
          </p>
        </div>
        <div className={cn("container", styles.container)}>
          <div className={styles.wrapper}>
            <Slider className="creative-slider" {...settings}>
              {items.map((x, index) => (
                <div className={styles.slide} key={index}>
                  <div className={styles.row}>
                    <Player className={styles.player} item={x} />
                    <div className={styles.details}>
                      <div className={styles.stage}>Squadron is for...</div>
                      <div className={cn("h1", styles.subtitle)}>{x.useCase}</div>
                      <div className={styles.line}>
                        <div className={styles.item}>
                          <div className={styles.avatar}>
                            <img src={x.logo1} alt="Avatar" />
                          </div>
                          <div className={styles.description}>
                            <div className={styles.category}>Similar to</div>
                            <div className={styles.text}>{x.company1}</div>
                          </div>
                        </div>
                        <div className={styles.item}>
                          <div className={styles.avatar}>
                            <img src={x.logo2} alt="Avatar" />
                          </div>
                          <div className={styles.description}>
                            <div className={styles.category}>Similar to</div>
                            <div className={styles.text}>{x.company2}</div>
                          </div>
                        </div>
                        {/* <div className={styles.item}>
                          <div className={styles.icon}>
                            <Icon name="twitter" size="24" />
                          </div>
                          <div className={styles.description}>
                            <div className={styles.category}>Main Platform</div>
                            <div className={styles.text}>Twitter</div>
                          </div>
                        </div> */}
                      </div>
                      <p className={styles.info2}>
                        A coach with 183,000 Twitter followers, who develops content to find leads, 
                        needs an easier way to exchange more value from his most profitable customers.
                        He uses Twitter to promote his coaching business to a broad audience, finding new 
                        leads and building relationships.
                      </p>
                      <p className={styles.info2}>
                        He is tech-savvy and always seeking for new opportunities to grow his brand and avoid
                        Twitter pain points such as shadow-banning, spam and bots, and limited analytics. 
                      </p>
                      {/* <div className={styles.wrap}>
                        <div className={styles.info}>Leading</div>
                        <div className={styles.currency}>{x.currency}</div>
                        <div className={styles.price}>{x.price}</div>
                        <div className={styles.info}>Squad Stats</div>
                        <div className={styles.timer}>
                          <div className={styles.box}>
                            <div className={styles.number}>4</div>
                            <div className={styles.time}>Squads</div>
                          </div>
                          <div className={styles.box}>
                            <div className={styles.number}>16</div>
                            <div className={styles.time}>VIPs</div>
                          </div>
                          <div className={styles.box}>
                            <div className={styles.number}>99</div>
                            <div className={styles.time}>secs</div>
                          </div>
                        </div>
                      </div>
                      <div className={styles.btns}>
                        <button
                          className={cn("button", styles.button)}
                          onClick={() => setVisibleModalBid(true)}
                        >
                          Request invite
                        </button>
                        <Link
                          className={cn("button-stroke", styles.button)}
                          to="/item"
                        >
                          View creator profile
                        </Link>
                      </div> */}
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
      <Modal
        visible={visibleModalBid}
        onClose={() => setVisibleModalBid(false)}
      >
        <Connect />
      </Modal>
    </>
  );
};

export default Introduction;
