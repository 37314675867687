import React from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import styles from "./Description.module.sass";
import Image from "../../../components/Image";

const Description2 = () => {
  return (
    <div className={styles.section}>
      <div className={cn("container", styles.container, styles.containerDescription)}>
        <div className={styles.wrap}>
          <h1 className={cn("h1", styles.title2)}>
            Squadron let's you build engaged micro-communities and focus on value exchange.
          </h1>
          <p className={styles.stage2}>Squadron's squads enable you to interact and organize your VIP's. <span>Smaller audiences, bigger impact.</span></p>
          {/* <div className={styles.text}>
            Squadron revolutionizes creator-fan connections.
          </div> */}
          {/* <div className={styles.text}>
            Fan-creator connection revolutionized by Squadron.
          </div> */}
          {/* <div className={styles.text}>
            Revolutionizing creator-fan engagement.
          </div> */}          
          <div className={styles.btns}>
            <Link className={cn("button", styles.button)} to="/upload-variants">
              Join waitlist
            </Link>
            <Link className={cn("button-stroke", styles.button)} to="/search01">
              Learn more
            </Link>
          </div>
        </div>
        <div className={styles.gallery}>
          <div className={styles.preview}>
            <Image
              srcSet="/images/content/isometric.png 2x"
              srcSetDark="/images/content/isometric.png 2x"
              src="/images/content/isometric.png"
              srcDark="/images/content/isometric.png"
              alt="Isometric"
            />
          </div>
          {/* <div className={styles.preview}>
            <Image
              srcSet="/images/content/cube@2x.png 2x"
              srcSetDark="/images/content/cube-dark@2x.png 2x"
              src="/images/content/cube.png"
              srcDark="/images/content/cube-dark.png"
              alt="Cube"
            />
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Description2;
